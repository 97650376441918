var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tutti-dialog',{ref:"dialog",attrs:{"max-width":"400","buttons":[
        {
            label: 'Cancel',
            attrs: {
                color: 'grey darken-1',
                text: true,
            },
            on: {
                click() { _vm.$refs.dialog.hide(); }
            }
        },
        {
            label: 'Confirm',
            attrs: {
                color: 'indigo darken-1',
                text: true,
                disabled: !_vm.isValid,
            },
            on: {
                click: _vm.changePassword
            }
        },
    ]},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Change Password ")]},proxy:true},{key:"body",fn:function(){return [_c('v-card-text',[_c('password-form',{ref:"passwordForm",attrs:{"old-password":_vm.oldPassword,"new-password1":_vm.newPassword1,"new-password2":_vm.newPassword2,"is-valid":_vm.isValid},on:{"update:oldPassword":function($event){_vm.oldPassword=$event},"update:old-password":function($event){_vm.oldPassword=$event},"update:newPassword1":function($event){_vm.newPassword1=$event},"update:new-password1":function($event){_vm.newPassword1=$event},"update:newPassword2":function($event){_vm.newPassword2=$event},"update:new-password2":function($event){_vm.newPassword2=$event},"update:isValid":function($event){_vm.isValid=$event},"update:is-valid":function($event){_vm.isValid=$event},"enter":_vm.changePassword}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }