var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","no-click-animation":"","value":true,"max-width":"700"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-alert")]),_vm._v("Set up admin account")],1),_c('v-card-text',[_vm._v(" Your Tutti environment does not have any account to log in with."),_c('br'),_vm._v(" Please register your first ("),_c('b',[_vm._v("admin")]),_vm._v(") account below: "),_c('account-form',{attrs:{"user-name":_vm.userName,"password":_vm.password,"is-valid":_vm.isValid},on:{"update:userName":function($event){_vm.userName=$event},"update:user-name":function($event){_vm.userName=$event},"update:password":function($event){_vm.password=$event},"update:isValid":function($event){_vm.isValid=$event},"update:is-valid":function($event){_vm.isValid=$event},"enter":_vm.submit}})],1),_c('tutti-dialog-actions',{attrs:{"actions":[
                {
                    label: 'confirm',
                    color: 'indigo darken-2',
                    callback: _vm.submit,
                    disabled: !_vm.isValid
                }
            ]}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }