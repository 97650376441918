var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(_vm.schemeConfig.ShowTitle)?_c('div',{staticClass:"text-h3 my-3",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.schemeConfig.Title)+" ")]):_vm._e(),_c('tutti-dialog',{ref:"dialogInstruction",attrs:{"maxWidth":"1000","buttons":[
            {
                label: 'Close',
                attrs: { color: 'green darken-1', text: true },
                on: { click() { _vm.dialogInstruction.hide(); } }
            }
        ]},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.schemeConfig.InstructionBtn)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-8"},'v-btn',attrs,false),on),[_vm._v(" Show Instruction ")]):_vm._e()]}},{key:"body",fn:function(){return [_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-n4",attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.dialogInstruction.hide()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"px-3"},[_c(_vm.instructionTemplate,{tag:"component"})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }