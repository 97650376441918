var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"1000px"},attrs:{"pa-10":""}},[_c('v-card',{staticClass:"my-10"},[_c('v-system-bar',{attrs:{"window":"","dark":"","color":"indigo"}},[_c('h3',[_vm._v("Your task")])]),_c('div',{staticClass:"pa-4 text-body-1 font-weight-medium"},[_vm._v(" Choose the one that best describes the video content. ")])],1),_c('v-card',{staticClass:"mx-auto my-6 pa-8",attrs:{"max-width":"1000"}},[_c('t-video',{attrs:{"video-props":{
                width: 600,
                controls: true
            },"source-props":{
                src: _vm.nano.props.videoPath,
                type: 'video/mp4'
            }}}),_c('v-radio-group',{model:{value:(_vm.nano.ans.choice),callback:function ($$v) {_vm.$set(_vm.nano.ans, "choice", $$v)},expression:"nano.ans.choice"}},_vm._l((_vm.choices),function(choice){return _c('v-radio',{key:choice.val,attrs:{"label":choice.text,"value":choice.val}})}),1),_c('v-btn',{attrs:{"disabled":_vm.nano.ans.choice === null,"color":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Submit ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }