var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"grey lighten-4"},[(_vm.credentials && _vm.credentials['access_key_id'])?_c('div',[(_vm.credentials['is_sandbox'])?_c('v-system-bar',{attrs:{"dark":"","color":"warning"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('console.platforms.mturk.modeToolbar.sandbox'))}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.setActiveSandboxMode(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}],null,false,3879499659)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(
                        'console.platforms.mturk.modeChange',
                        { mode: _vm.$t('console.platforms.mturk.mode.production') }
                    ))}})]),_c('v-spacer'),_c('span',[_vm._v(" Access Key ID: "),_c('b',[_vm._v(_vm._s(_vm.credentials['access_key_id']))])])],1):_c('v-system-bar',{attrs:{"dark":"","color":"error"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('console.platforms.mturk.modeToolbar.production'))}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loading},on:{"click":function($event){return _vm.setActiveSandboxMode(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}],null,false,600667456)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(
                        'console.platforms.mturk.modeChange',
                        { mode: _vm.$t('console.platforms.mturk.mode.sandbox') }
                    ))}})]),_c('v-spacer'),_c('span',[_vm._v(" AccessKeyId: "),_c('b',[_vm._v(_vm._s(_vm.credentials['access_key_id']))]),_vm._v(" ("+_vm._s(_vm.$t('console.platforms.mturk.modeToolbar.balance'))+": "),_c('b',[_vm._v("$"+_vm._s(_vm.credentials['account_balance']['available_balance']))]),_vm._v(") ")])],1)],1):_c('div',[_c('v-system-bar',{attrs:{"color":"grey lighten-2"}},[(_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('console.platforms.mturk.modeToolbar.loading'))+" "),_c('v-btn',{attrs:{"icon":"","disabled":"","loading":""}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('console.platforms.mturk.modeToolbar.notSet'))+" ")])])],1),_c('keep-alive',{attrs:{"exclude":['HIT-Create']}},[_c('router-view',{attrs:{"duct":_vm.duct,"projectName":_vm.projectName,"credentials":_vm.credentials,"client":_vm.client}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }