var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tutti-dialog',{ref:"dialog",attrs:{"maxWidth":"700","buttons":[
        {
            label: _vm.$t('console.templates.responseDialog.closeButton'),
            attrs: {
                color: 'indigo darken-1',
                text: true
            },
            on: {
                click() { _vm.$refs.dialog.hide(); }
            }
        }
    ]},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]),_vm._v(_vm._s(_vm.$t('console.templates.responseDialog.title'))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('v-card-text',[_c('vue-json-pretty',{attrs:{"data":_vm.nanoAnswers}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }