import { render, staticRenderFns } from "./TimelineTitleFooter.vue?vue&type=template&id=386882f5&scoped=true&"
import script from "./TimelineTitleFooter.vue?vue&type=script&lang=js&"
export * from "./TimelineTitleFooter.vue?vue&type=script&lang=js&"
import style0 from "./TimelineTitleFooter.vue?vue&type=style&index=0&id=386882f5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386882f5",
  null
  
)

export default component.exports