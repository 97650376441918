var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.wrapperStyle),attrs:{"id":"wrapper"}},[_c('div',{attrs:{"id":"camera-preview"}},[_c('video',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShown || _vm.alwaysShowCamera),expression:"videoShown || alwaysShowCamera"}],ref:"video",class:_vm.flipCamera ? 'flip' : '',attrs:{"id":"video","autoplay":"","playsinline":""}}),_vm._t("camera-preview")],2),_c('div',{attrs:{"id":"div-overlay"}},[_vm._t("overlay",null,null,{
                videoShown: _vm.videoShown,
                isStandby: _vm.isStandby,
                standBy: _vm.standBy,
                capturePhoto: _vm.capturePhoto,
                clear: _vm.clear
            })],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }